<template>
  <div>
    <Pane />

    <div class="container">
      <div class="wrapper">
        <div class="title">{{ doc.title }}</div>
        <div class="description">
          <a-icon type="user" style="margin-right: 4px" />
          <span>{{ doc.source }}</span>
          <a-divider type="vertical" />
          <a-icon type="eye" style="margin-right: 4px" />
          <span>{{ doc.views }}</span>
          <a-divider type="vertical" />
          <a-icon type="clock-circle" style="margin-right: 4px" />
          <span>{{ $getDateString(doc.publishAt) }}</span>
        </div>

        <div class="content" v-html="content"></div>

        <a-divider />
        <div class="list-title">附件</div>
        <div class="list">
          <a
            class="item"
            v-for="item in list"
            :key="item"
            :href="item"
            target="_blank"
            >{{ $getFileName(item) }}</a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import watermark from "@/mixins/watermark";
import { fetchDetail } from "@/api/oa/quality";
export default {
  mixins: [watermark],

  data() {
    return {
      doc: {},
    };
  },

  computed: {
    content() {
      return this.doc.content
        ? this.doc.content.replace(
            /<img/g,
            "<img style='width: 100%;display: block'"
          )
        : "";
    },
    list() {
      return typeof this.doc.attachments === "string"
        ? this.doc.attachments.split(",")
        : [];
    },
  },

  mounted() {
    const { query } = this.$route;
    const { id } = query || {};

    if (id) {
      fetchDetail({
        id,
      }).then((res) => {
        this.doc = res;
      });
    }
  },
};
</script>


<style lang="less" scoped>
.container {
  background-color: #fff;
  padding: 24px 240px;
}

.title {
  font-weight: bold;
  font-size: 24px;
  margin-bottom: 12px;
  text-align: center;
}

.content {
  font-size: 14px;
}

.description {
  font-size: 12px;
  margin-bottom: 24px;
  text-align: center;
}
.cover-picture {
  width: 100%;
  margin-bottom: 24px;
}

.list-title {
  font-weight: bold;
  font-size: 14px;
  margin-bottom: 4px;
}
.list {
  .item {
    display: block;
    margin-bottom: 4px;
  }
}
</style>